import { Alert, Button, Card, Form, InputGroup } from "react-bootstrap"
import { updateObjectState } from "../utils/functions"
import { ConditionalView } from "../utils/components"
import TicketsForm from "./TicketsForm"
import { sectionSpace } from "../constants"

export function TicketSelection({
  event,
  data,
  setData,
  selectedTicket,
  setSelectedTicket,
}) {
  return (
    <div>
      <Card>
        <Card.Header className={"bg-primary text-center py-4 text-white"}>
          <h2>Purchase Tickets</h2>
        </Card.Header>

        <Card.Body>
          <Card.Title>Select the ticket type you want to purchase*</Card.Title>
          <Card.Subtitle>
            * means that the field is required to be filled.
          </Card.Subtitle>

          <div className={sectionSpace} />

          <TicketsForm
            event={event}
            data={data}
            setData={setData}
            selectedTicket={selectedTicket}
            setSelectedTicket={setSelectedTicket}
          />
        </Card.Body>
      </Card>

      <TicketsDeliveryForm
        data={data}
        setData={setData}
      />
    </div>
  )
}

function TicketsDeliveryForm({ data, setData }) {
  return (
    <Card className={"mt-3 pb-4"}>
      <Card.Header className={"text-center text-primary py-4"}>
        <h4>Ticket Delivery Method</h4>
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Label className={"text-center"}>
            How do you want us to send the tickets to you?
          </Form.Label>
          <br />
          <Form.Label className={"my-3"}>
            <strong>Enter a name for each ticket</strong>
          </Form.Label>
          {data.attendee_names.map((item, i) => {
            const index = i + 1
            const key = `Name${i}`
            return (
              <div
                key={i}
                className={"mb-3"}
              >
                <Form.Label>Name {index}*</Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  placeholder="First & Last Name"
                  className={"border-2 border-primary"}
                  value={data.attendee_names[i]}
                  onChange={(e) => {
                    const names = [...data.attendee_names]
                    names[i] = e.target.value
                    updateObjectState(setData, { attendee_names: names })
                  }}
                />
              </div>
            )
          })}

          <div className={sectionSpace} />

          <Form.Label className={"mb-3"}>
            How should we send your ticket code(s)?*
          </Form.Label>
          <div className="d-grid gap-2">
            <InputGroup className="mb-3">
              <Button
                variant={!data.isEmail ? "outline-primary" : "primary"}
                id="button-addon1"
                className={`${data.isEmail ? "text-white" : ""}`}
                onClick={() => {
                  updateObjectState(setData, {
                    isEmail: true,
                    contact: "",
                  })
                }}
              >
                Email Message
              </Button>
              <Button
                variant={data.isEmail ? "outline-primary" : "primary"}
                id="button-addon2"
                className={`${data.isEmail ? "" : "text-white"}`}
                onClick={() => {
                  updateObjectState(setData, {
                    isEmail: false,
                    contact: "",
                  })
                }}
              >
                WhatsApp Message
              </Button>
            </InputGroup>
          </div>

          <ConditionalView
            condition={data.isEmail}
            trueView={
              <InputGroup size={"lg"}>
                <Form.Control
                  type={"email"}
                  id="contactEmail"
                  value={data.contact}
                  className={"border-2 border-primary"}
                  placeholder={"Enter your email address"}
                  onChange={(e) => {
                    e.stopPropagation()
                    const val = e.target.value
                    updateObjectState(setData, { contact: val })
                  }}
                />
              </InputGroup>
            }
            falseView={
              <div>
                <Alert
                  variant={"danger"}
                  // className={"text-muted"}
                >
                  Enter your WhatsApp phone number INCLUDING the COUNTRY CODE.
                  <br />
                  No need to add the plus sign (+) or any other special
                  characters.
                </Alert>
                <InputGroup
                  className="mb-3"
                  size={"lg"}
                >
                  <InputGroup.Text id="countryCode">+</InputGroup.Text>
                  <Form.Control
                    placeholder="e.g. 23277123456"
                    aria-label="Your WhatsApp #"
                    aria-describedby="WhatsApp Phone number input"
                    type={"number"}
                    id="contactPhoneNumber"
                    className={"border-2 border-primary"}
                    value={data.contact}
                    onChange={(e) => {
                      e.stopPropagation()
                      let val = e.target.value
                      if (val.includes("+")) {
                        // global replace plus sign
                        val = val.replace(/\+/g, "")
                      }
                      updateObjectState(setData, { contact: val })
                    }}
                  />
                </InputGroup>
              </div>
            }
          />
        </Form>
      </Card.Body>
    </Card>
  )
}
