import { Button, Modal } from "react-bootstrap"

export function ConditionalView({ condition, trueView, falseView }) {
  if (condition) {
    return <>{trueView}</>
  } else {
    return <>{falseView}</>
  }
}

export function LoadingView({ isLoading, view, text = "Loading..." }) {
  return (
    <ConditionalView
      condition={isLoading}
      trueView={
        <div className={"text-center mt-5"}>
          <br />
          <br />
          <div
            className="spinner-border text-primary"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
          <h5 className={"mt-3"}>{text}</h5>
        </div>
      }
      falseView={<>{view}</>}
    />
  )
}

export function DisplayText({ text }) {
  return <span style={{ whiteSpace: "pre-line" }}>{text}</span>
}

export function AlertModal({ modalInfo, showModal, setShowModal, onYesClick }) {
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
    >
      <Modal.Header
        closeButton
        closeVariant={"white"}
        className={`${
          modalInfo.isError ? "bg-danger" : "bg-success"
        } text-white`}
      >
        <Modal.Title>{modalInfo.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {typeof modalInfo.body === "string" ? (
          <DisplayText text={modalInfo.body} />
        ) : (
          modalInfo.body
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShowModal(false)
          }}
        >
          {!modalInfo.interactive ? "Ok" : "No"}
        </Button>
        {modalInfo.interactive && (
          <Button
            variant="primary"
            onClick={() => {
              onYesClick()
            }}
          >
            Yes
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}
