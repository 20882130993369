import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Image,
  ListGroup,
  Alert,
  Form,
} from "react-bootstrap"
import {
  FaDownload,
  FaUserPlus,
  FaMoneyBill,
  FaQuestionCircle,
  FaTrophy,
  FaBell,
} from "react-icons/fa"
import NavBar from "./NavBar"
import appStore from "../assets/images/app-store.png"
import playStore from "../assets/images/play-store.png"
import { downLoadVisit } from "../analytics"
import ReactGA from "react-ga"
import { useParams } from "react-router-dom"
import { apiRequest } from "../requests"
import { AlertModal, LoadingView } from "../utils/components"

// ReactGA.initialize("UA-161757367-7")

function CardPaymentVerification() {
  const urlParams = new URLSearchParams(window.location.search)
  const orderNumber = urlParams.get("orderNumber")

  // get payment id form url
  const { paymentId } = useParams()

  const [data, setData] = useState({
    payment_transaction_id: paymentId,
    verification_code: "",
  })
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modalInfo, setModalInfo] = useState({})

  const confirmPayment = async () => {
    setIsLoading(true)
    setShowModal(false)
    const { success, response } = await apiRequest.post(
      `api/event/pay-verify`,
      data
    )

    if (success) {
      setModalInfo({
        title: "Success",
        body: response.message,
      })
    } else {
      setModalInfo({
        title: "Error",
        body: response.message,
        isError: true,
      })
    }
    setShowModal(true)
    setIsLoading(false)
  }

  return (
    <div>
      <NavBar />
      <Container>
        <LoadingView
          isLoading={isLoading}
          view={
            <Row>
              <Col lg={{ span: 7, offset: 3 }}>
                <Card className={"my-5"}>
                  <Card.Header className={"py-4 bg-primary text-white"}>
                    <h4>Payment Verification</h4>
                  </Card.Header>
                  <Card.Body>
                    <h3 className={"text-center text-primary my-4"}>
                      {orderNumber}
                      <br />
                      <small>Order #</small>
                    </h3>

                    {/*Input field to enter 6-digit code*/}
                    <ListGroup className={"mb-3"}>
                      <ListGroup.Item>
                        <h5 className={"mb-3"}>
                          Verification Code{" "}
                          <small className={"text-danger"}>(required)</small>
                        </h5>
                        <Alert variant={"info"}>
                          Enter the 6-digit code in your payment / card account
                          transaction description. It should look something like
                          <br />
                          <strong>CODE: 123456 | VENTIX</strong>
                        </Alert>

                        <Form.Control
                          type="number"
                          className={"border-2 border-primary"}
                          placeholder={"Enter the 6-digit code"}
                          maxLength={6}
                          value={data.verification_code || ""}
                          onChange={(e) =>
                            setData({
                              ...data,
                              verification_code: e.target.value,
                            })
                          }
                        />
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>

                  <Card.Footer className={"text-center py-4"}>
                    <Button
                      variant={"primary"}
                      disabled={
                        !data.verification_code ||
                        data.verification_code.length < 6
                      }
                      onClick={() => {
                        confirmPayment()
                      }}
                    >
                      Verify Payment
                    </Button>
                  </Card.Footer>
                </Card>
              </Col>
              <AlertModal
                modalInfo={modalInfo}
                showModal={showModal}
                setShowModal={setShowModal}
              />
            </Row>
          }
        />
      </Container>
    </div>
  )
}
export default CardPaymentVerification
