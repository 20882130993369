import { Alert, Button, Card, Image, ListGroup } from "react-bootstrap"
import logo from "../assets/images/cardsLogo.jpg"
import logoBlue from "../assets/images/stripeLogoBlue.png"
import { constants } from "../constants"
import { useEffect, useState } from "react"
import { isEmpty, updateObjectState } from "../utils/functions"

const color = "#008cdd"

export function StripePaymentButton({ onClick }) {
  return (
    <div
      className={"text-center pointer"}
      style={{
        backgroundColor: color,
        borderRadius: 20,
        paddingTop: 15,
        paddingBottom: 15,
      }}
      onClick={() => onClick(constants.paymentProcessors.stripe)}
    >
      <Image
        src={logo}
        rounded={true}
        height={"40px"}
        // style={{marginT}}
      />
    </div>
  )
}

export function StripePayment({
  data,
  setData,
  selectedTicket,
  validateForm,
  submitPayment,
}) {
  const [paymentEmail, setPaymentEmail] = useState(null)

  useEffect(() => {
    if (data.isEmail) {
      setPaymentEmail(data.contact)
    }
  }, [])

  useEffect(() => {
    updateObjectState(setData, {
      payment_email: paymentEmail,
    })
  }, [paymentEmail])

  return (
    <Card className={""}>
      <Card.Header
        className={"py-2"}
        style={{ backgroundColor: color }}
      >
        <h4 className={"text-center text-white"}>
          Pay with Credit / Debit Card
        </h4>
      </Card.Header>
      <Card.Body>
        <Alert variant={"warning"}>
          <Alert.Heading>Authorization & Fraud Prevention Notice</Alert.Heading>
          After you complete payment, we will place a temporary hold on your
          card, and the transaction description will include a 6-digit code.
          <br />
          We will send you a link at the email address below where you will need
          to enter this code to complete the transaction. and get your tickets /
          votes
        </Alert>
        <div className={"mt-5"} />

        <ListGroup className={"mb-3"}>
          <ListGroup.Item>
            <h6 className={"mb-3"}>
              Payment Email <small className={"text-danger"}>(required)</small>{" "}
            </h6>
            <input
              type="email"
              className={"form-control border-2 border-primary"}
              value={paymentEmail || ""}
              onChange={(e) => setPaymentEmail(e.target.value)}
            />
          </ListGroup.Item>
        </ListGroup>
        <Alert
          variant={"info"}
          className={"small"}
        >
          This email does not have to be the same as the one we are sending the
          tickets / votes to
        </Alert>
        <div className={"mt-5"} />

        <div className="d-grid gap-2 my-4">
          <Button
            className={"text-white"}
            disabled={
              !selectedTicket || validateForm() || isEmpty(paymentEmail)
            }
            variant="primary"
            size={"lg"}
            style={{ backgroundColor: color }}
            onClick={(e) => {
              e.preventDefault()
              submitPayment()
            }}
          >
            Proceed to Checkout
          </Button>
        </div>
      </Card.Body>
      <Card.Footer>
        <h4 className={"mb-2"}>Powered By:</h4>
        <Card.Img
          variant="top"
          src={logoBlue}
        />
      </Card.Footer>
    </Card>
  )
}
