import { Button, Card, Col, Row, Form, InputGroup } from "react-bootstrap"
import { updateObjectState } from "../utils/functions"
import { useEffect, useMemo, useState } from "react"
import { BiSortDown, BiSortUp } from "react-icons/bi"
import { sectionSpace } from "../constants"

export default function TicketsForm({
  event,
  data,
  setData,
  selectedTicket,
  setSelectedTicket,
}) {
  const [tickets, setTickets] = useState(event.tickets)
  const [selectedCurrency, setSelectedCurrency] = useState("SLE")
  const [sortOrder, setSortOrder] = useState("asc") // 'asc' or 'desc'

  // Function to filter tickets by currency
  const filterTicketsByCurrency = (currency) => {
    setSelectedCurrency(currency)
    const filteredTickets = event.tickets.filter(
      (ticket) => ticket.currency === currency
    )
    setTickets(filteredTickets)

    if (filteredTickets.length > 0) {
      const ticket = filteredTickets[0]
      updateObjectState(setData, {
        tier_id: ticket.id,
      })
      setSelectedTicket(ticket)
    }
  }

  // Function to toggle sorting order by price
  const toggleSortOrder = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc"
    setSortOrder(newSortOrder)
    sortTicketsByPrice(newSortOrder)
  }

  // Function to sort tickets by price
  const sortTicketsByPrice = (order) => {
    const sortedTickets = [...tickets].sort((a, b) => {
      if (order === "asc") {
        return a.retail_price - b.retail_price
      } else {
        return b.retail_price - a.retail_price
      }
    })
    setTickets(sortedTickets)
  }

  useEffect(() => {
    if (selectedTicket && selectedTicket.quantity > 0) return
    // Set the selected currency to "SLE" upon first render
    setSelectedCurrency("SLE")
    // Filter the tickets based on the initial "SLE" currency
    filterTicketsByCurrency("SLE")
  }, [event.tickets])

  return (
    <div>
      <Row className="mb-4">
        <Col>
          <InputGroup>
            <Button
              variant={
                selectedCurrency !== "SLE" ? "outline-primary" : "primary"
              }
              id="button-addon1"
              className={`${selectedCurrency === "SLE" ? "text-white" : ""}`}
              onClick={() => {
                filterTicketsByCurrency("SLE")
                updateObjectState(setData, {
                  currency: "SLE",
                })
              }}
            >
              SLE Ticket(s)
            </Button>
            <Button
              variant={
                selectedCurrency === "SLE" ? "outline-primary" : "primary"
              }
              id="button-addon2"
              className={`${selectedCurrency === "SLE" ? "" : "text-white"}`}
              onClick={() => {
                filterTicketsByCurrency("USD")
                updateObjectState(setData, {
                  currency: "USD",
                })
              }}
            >
              USD Ticket(s)
            </Button>
          </InputGroup>
        </Col>
        {/*Comenting out for now as there are always just a few tickets. Sorting is an overkill*/}
        {/*<Col className="text-end">*/}
        {/*  <Button*/}
        {/*    className="text-white"*/}
        {/*    onClick={toggleSortOrder}*/}
        {/*  >*/}
        {/*    Sort by Price {sortOrder === "asc" ? <BiSortDown /> : <BiSortUp />}*/}
        {/*  </Button>*/}
        {/*</Col>*/}
      </Row>
      <Form>
        {tickets.length === 0 ? (
          <label className="text-red">No Ticket available</label>
        ) : (
          tickets.map((ticket, i) => {
            const selected = ticket.id === selectedTicket.id
            return (
              <Card
                key={i}
                body={true}
                className={`mb-3 justify-content-between pointer ${
                  selected ? "border-primary border-2" : "border-secondary"
                }`}
                onClick={() => {
                  updateObjectState(setData, {
                    tier_id: ticket.id,
                    processor: "",
                  })
                  setSelectedTicket(ticket)
                }}
              >
                <div className={"d-flex justify-content-between"}>
                  <Form.Check
                    type={"radio"}
                    id={ticket.id}
                    value={"radio"}
                    checked={selected}
                    label={ticket.name}
                    className={"pointer"}
                    onChange={(e) => {
                      updateObjectState(setData, { tier_id: ticket.id })
                      setSelectedTicket(ticket)
                    }}
                  />
                  <div>
                    <strong>{ticket.formated_price}</strong>
                  </div>
                </div>

                <Card.Text className={"mt-3 small"}>
                  {ticket.description}
                </Card.Text>
              </Card>
            )
          })
        )}

        <div className={sectionSpace} />

        <Form.Label htmlFor="numberOfTickets"># of Tickets</Form.Label>
        <Col md={"6"}>
          <InputGroup>
            <Button
              variant="outline-primary"
              onClick={() => {
                if (data.quantity === 1) return
                const newQty = data.quantity - 1
                const names = [...data.attendee_names]
                names.splice(newQty, 1)
                updateObjectState(setData, {
                  quantity: newQty,
                  attendee_names: names,
                })
              }}
            >
              -1 Ticket
            </Button>
            <Form.Control
              readOnly={true}
              className={"text-center fs-3"}
              placeholder="Ticket Quantity"
              value={data.quantity}
            />
            <Button
              className={"text-white"}
              variant="primary"
              onClick={() => {
                if (
                  data.quantity === 10 ||
                  data.quantity > selectedTicket.quantity
                )
                  return
                updateObjectState(setData, {
                  quantity: data.quantity + 1,
                  attendee_names: [...data.attendee_names, ...[""]],
                })
              }}
            >
              +1 Ticket
            </Button>
          </InputGroup>
        </Col>
      </Form>
    </div>
  )
}
