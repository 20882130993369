import React from "react"

import appStore from "../assets/images/app-store.png"
import playStore from "../assets/images/play-store.png"
import { Card, Image } from "react-bootstrap"
import { appConfigs } from "../configs/config"
import { downLoadVisit } from "../analytics"

function DownloadAppComponent({
  subtitle = "Unlock Events, Finances, Voting & More",
}) {
  return (
    <Card
      bg={"primary"}
      className={"text-white"}
    >
      <Card.Header className={"py-3"}>
        <h3 className={"fw-bolder"}>Download the {appConfigs.appName} App</h3>
      </Card.Header>
      <Card.Body>
        <strong>{subtitle}</strong>
        <div className={"row pt-3"}>
          <p>
            <Image
              className={"pointer"}
              fluid={false}
              src={playStore}
              height={"auto"}
              width={"150px"}
              onClick={() =>
                downLoadVisit("android", `${appConfigs.appName} Web`)
              }
            />
            <span className={"mx-2"}></span>
            <Image
              className={"pointer"}
              fluid={false}
              src={appStore}
              height={"auto"}
              width={"150px"}
              onClick={() => downLoadVisit("ios", `${appConfigs.appName} Web`)}
            />
          </p>
        </div>
      </Card.Body>
    </Card>
  )
}

export default DownloadAppComponent
