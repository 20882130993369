import React, { useState } from "react"
import { Button, InputGroup, FormControl } from "react-bootstrap"

const CopyLinkComponent = ({ link }) => {
  const [copied, setCopied] = useState(false)

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(link)
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 2000) // Reverts the button text back to "Copy" after 2 seconds
    } catch (err) {
      console.error("Failed to copy: ", err)
    }
  }

  return (
    <InputGroup className="mb-3">
      <FormControl
        readOnly
        value={link}
        className={"border-1 border-primary"}
      />
      <Button
        variant={copied ? "success" : "primary"}
        className={"text-white"}
        onClick={copyToClipboard}
      >
        {copied ? "Copied!" : "Copy"}
      </Button>
    </InputGroup>
  )
}

export default CopyLinkComponent
