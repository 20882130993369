const webAddress = "getventix.com"
const env = process.env.REACT_APP_ENV

const sharedConfigs = {
  appName: "Ventix",
  isDev: false,
  isStg: false,
  isProd: false,
  apiHostname: "http://10.0.0.104:5000",
  // feHostname: "http://10.0.0.105:3000",
  // apiHostname: "https://api.getventix.com/",
  feHostname: "http://localhost:3000",
  shareHostname: "https://share.getventix.com",
  omslNumberLength: 10,
}

const configs = {
  dev: {
    ...sharedConfigs,
    ...{
      isDev: true,
    },
  },
  stg: {
    ...sharedConfigs,
    ...{
      isStg: true,
      apiHostname: `https://stg-api.${webAddress}`,
      feHostname: `https://stg.${webAddress}`,
    },
  },
  prod: {
    ...sharedConfigs,
    ...{
      isProd: true,
      apiHostname: `https://api.${webAddress}`,
      feHostname: `https://${webAddress}`,
      omslNumberLength: 9,
    },
  },
}

export const appConfigs = configs[env]
