import React, { useState, useEffect } from "react"
import {
  Button,
  Modal,
  Form,
  InputGroup,
  Row,
  Col,
  Alert,
  Card,
} from "react-bootstrap"
import { appConfigs } from "../configs/config"
import { FaPhone } from "react-icons/fa"
import CopyLinkComponent from "./CopyLink"
import { updateObjectState } from "../utils/functions"

const OmslPayment = ({
  processorColor = "#FF6500",
  modalTitle = "Pay with Orange Money",
  data,
  setData,
  selectedTicket,
  validateForm,
  submitPayment,
}) => {
  const OMSL_SHORT_CODE = "#144*3*12#"
  const OMSL_SHORT_COD_PINE = "#144*3*12*{pin}#"

  const [currentStep, setCurrentStep] = useState(1)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const isMobileDevice = /iPhone|Mobi|Android/i.test(navigator.userAgent)
    setIsMobile(isMobileDevice)
  }, [])

  const inputValid = () => {
    if (currentStep === 1) {
      return data.account_number.length === appConfigs.omslNumberLength
    } else if (currentStep === 2) {
      return data.otp.length === 6
    }
  }

  return (
    <Card>
      <Card.Header
        style={{ backgroundColor: processorColor }}
        className={"text-white py-3 text-xenter"}
      >
        <h4>{modalTitle}</h4>
      </Card.Header>
      <Card.Body className={"pt-4"}>
        {currentStep >= 1 && (
          <Form>
            <Form.Group className={"mb-4"}>
              <p className={"fw-bold"}>Enter Orange Money Number</p>
              <Form.Control
                type="number"
                placeholder="e.g. 073778877"
                maxLength={appConfigs.omslNumberLength}
                max={parseInt("9".repeat(appConfigs.omslNumberLength))}
                value={data.account_number}
                className={"border-2"}
                style={{ borderColor: processorColor }}
                onChange={(e) =>
                  setData({
                    ...data,
                    account_number: e.target.value,
                  })
                }
              />
            </Form.Group>

            {inputValid() && (
              <div className={"mt-2"}>
                <p className={"mb-1 fw-bold"}>
                  Get Payment Code from Orange Money
                </p>
                <p className={"small mb-3"}>
                  Dial the code below to get your 6-digit payment code and enter
                  it below
                </p>

                {isMobile && (
                  <Button
                    className={"w-100 text-white fw-bold"}
                    style={{ backgroundColor: processorColor }}
                    onClick={() => {
                      window.location.href = `tel:${encodeURIComponent(
                        OMSL_SHORT_CODE
                      )}`
                    }}
                  >
                    <FaPhone className={"me-2"} />
                    Dial {OMSL_SHORT_CODE}
                  </Button>
                )}

                {!isMobile && <CopyLinkComponent link={OMSL_SHORT_CODE} />}

                <Form.Group className={"mt-4"}>
                  <p className={"fw-bold"}>Enter 6-digit Code</p>
                  <Form.Control
                    type="number"
                    placeholder="e.g. 123456"
                    maxLength={6}
                    max={999999}
                    value={data.otp}
                    className={"border-2"}
                    style={{ borderColor: processorColor }}
                    onChange={(e) => setData({ ...data, otp: e.target.value })}
                  />
                </Form.Group>

                <Button
                  variant={"primary"}
                  className={"mt-4 text-white w-100"}
                  disabled={
                    data?.otp?.length !== 6 || !selectedTicket || validateForm()
                  }
                  onClick={() => submitPayment()}
                >
                  Submit Payment
                </Button>
              </div>
            )}
          </Form>
        )}
      </Card.Body>
    </Card>
  )
}

export default OmslPayment
